













































































import { Component, Vue, Watch } from 'vue-property-decorator';
import './scss/HeaderSearch.scss';
import debounce from '../../../Modules/debounce.js';
import { ProjectActions } from '@store/modules/project/Types';
import { namespace } from 'vuex-class';
import { IProject } from '@store/modules/project/Interfaces';
import { WorkListActions } from '@store/modules/work-list/Types';

const NSProject = namespace('storeProject');
const NSWorkList = namespace('storeWorkList');

@Component({
    name: 'HeaderSearch',

    components: {
        BaseInput: () => import('@components/BaseInputs/BaseInput.vue'),
        BasePreloader: () => import('@components/BasePreloader/BasePreloader.vue'),
    },
})
export default class HeaderSearch extends Vue {
    @NSProject.Action(ProjectActions.A_SEARCH_IN_PROJECT) searchInProject!: (any) => Promise<void>;
    @NSProject.Action(ProjectActions.A_SET_SEARCH_RESULT) setSearchResult!: (any) => Promise<void>;
    @NSProject.Action(ProjectActions.A_SET_SEARCHING_PROCESS) setSearchingProcess!: (any) => Promise<void>;
    @NSProject.Getter('searchingProcess') searchingProcess!: boolean;
    @NSProject.Getter('projectData') projectData!: IProject;
    @NSWorkList.Action(WorkListActions.A_SET_SEARCH_RESULTS_IDS_CHAIN) setSearchResultsIdsChain!: (payload) => void;

    searchQuery: string = '';
    debounceDelay: number = 500;
    resultsDisplayed: boolean = false;
    resultsShow: boolean = false;
    resultsListAnimationDuration: number = 200;

    searchResults = {
        documents: [],
        works: [],
        constructs: [],
    }

    @Watch('searchQuery')
    watchSearchQuery() {
        this.debouncedChangeQuery();
    }

    get debouncedChangeQuery() {
        return debounce(this.onChangeQuery, this.debounceDelay);
    }

    get resultsDocuments() {
        return this.searchResults.documents;
    }

    get resultsWorks() {
        return this.searchResults.works;
    }

    get resultsConstructs() {
        return this.searchResults.constructs;
    }

    get isSomethingInResults() {
        return this.resultsDocuments.length || this.resultsWorks.length || this.resultsConstructs.length;
    }

    get formattedSearchResults() {
        const output: any[] = [];
        if (this.resultsDocuments && this.resultsDocuments.length) {
            output.push({
                title: 'Документы',
                items: this.searchResults.documents,
            });
        }
        if (this.resultsWorks && this.resultsWorks.length) {
            output.push({
                title: 'Работы',
                items: this.searchResults.works,
            });
        }
        if (this.resultsConstructs && this.resultsConstructs.length) {
            output.push({
                title: 'Конструктивы',
                items: this.searchResults.constructs,
            });
        }
        return output;
    }

    onChangeQuery() {
        if (this.searchQuery.length) {
            this.setSearchingProcess(true);
            this.searchInProject({
                appId: this.projectData.id,
                query: this.searchQuery,
            }).then((res) => {
                this.searchResults.documents = res['documents'];
                this.searchResults.works = res['works'];
                this.searchResults.constructs = res['constructs'];

                if (this.isSomethingInResults && this.isSomethingInResults > 0) {
                    this.openResults();
                } else {
                    this.closeResults();
                }
            }).finally(() => {
                this.setSearchingProcess(false);
            })
        } else {
            this.resetResults();
            this.closeResults();
        }
    }

    resetResults() {
        this.searchResults = {
            documents: [],
            works: [],
            constructs: [],
        }
    }

    openResults() {
        this.resultsDisplayed = true;
        this.resultsShow = true;
        setTimeout(() => {
            if (this.resultsShow) {
                document.addEventListener('click', this.documentClickHandler);
            }
        }, 0);
    }

    documentClickHandler() {
        this.resultsShow = false;
        document.removeEventListener('click', this.documentClickHandler);
    }

    closeResults() {
        this.resultsShow = false;
        document.removeEventListener('click', this.documentClickHandler);
        setTimeout(() => {
            this.resultsDisplayed = false;
        }, this.resultsListAnimationDuration);
    }

    resetSearch() {
        this.closeResults();
        setTimeout(() => {
            this.resetResults();
            this.searchQuery = '';
        }, this.resultsListAnimationDuration);
    }

    onClickOnSearchResult(group, item) {
        setTimeout(( )=> {
            this.setSearchResult({ group, item }).then(() => {
                this.closeResults();
                this.setSearchingProcess(true);
                if (group === 'Документы' && this.$route.name !== 'project-documents') {
                    this.$router.push('documents');
                } else if (group !== 'Документы' && this.$route.name !== 'work-list') {
                    this.$router.push('work-list');
                }
            });
        }, 600);
    }
}
